import { Injector, signal, WritableSignal } from '@angular/core';
import { ScreenService } from '../../services/screen.service';
import { WidgetService } from '../../services/widget.service';

export abstract class WidgetFetcher {
  widgetData: WritableSignal<Record<string, any>> = signal({});
  isWidgetFetched = signal(false);
  intersect: WritableSignal<any> = signal({});
  pageKey = '';

  public widgetService: WidgetService;
  public screenService: ScreenService;

  constructor(injector: Injector) {
    this.widgetService = injector.get(WidgetService);
    this.screenService = injector.get(ScreenService);
  }

  public async fetchWidgetData(slugs: string[]) {
    this.isWidgetFetched.set(false);
    const widgetData = await this.widgetService.getData(slugs);
    if (widgetData) this.widgetData.set(widgetData);
    this.isWidgetFetched.set(true);
  }

  getValue(key: string, pageKey?: string) {
    return this.widgetData()[`${pageKey ?? this.pageKey}_${key}`];
  }

  onIntersect(key: string) {
    const value = this.intersect();
    value[key] = true;
    this.intersect.set(value);
  }

  get isMobile() {
    return this.screenService.isMobile;
  }
}
